//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import { docIdKey, getCol } from '@happstv/shared/util/firebase/firestoreUtils'
import { AGENCY_MEDIA_KITS_COL_PATH } from '@happstv/shared/util/firebase/firestorePaths'
import { numberMagnitudeString } from '@happstv/shared/util/utils'
import { TIKTOK_NATIVE, INSTAGRAM_NATIVE, YOUTUBE_NATIVE } from '@happstv/shared/util/multicastingConstants'

import { getPlatformIcon } from '@/util/connectedAccounts/externalPlatformUtils'
import { getAccountListFromMediaKit } from '@/util/agent/agencyUtils'

import ReachLogo from '@/assets/svg/app/ReachLogo.vue'
import PopupLayer from '@/components/universal/overlapping/PopupLayer.vue'
import AgencyLogo from '@/components/agent/AgencyLogo.vue'
import PublicMediaKitPreview from '@/components/agent/creator/mediaKit/PublicMediaKitPreview.vue'

const PublicMediaKitFrame = () => import('./PublicMediaKitFrame.vue')

const SORT_END_CHAR = 'Ξ' // this char comes after all of the alphanumeric chars

export default {
  name: 'PublicCreatorList',
  props: {
    creatorList: Object,
  },
  components: {
    ReachLogo,
    PopupLayer,
    AgencyLogo,
    PublicMediaKitPreview,
  },
  data() {
    return {
      getPlatformIcon,
      numberMagnitudeString,
      mediaKitList: undefined,
      mediaKitPreview: undefined,
      PublicMediaKitFrame,
      YOUTUBE_NATIVE,
    }
  },
  computed: {
    ...mapState([
      'isMobile',
    ]),
    agencyData() { return (this.creatorList || {}).agencyData || {} },
    creatorListId() { return (this.creatorList || {}).id },
    aggregatedFollowersByPlatform() {
      const { mediaKitList } = this
      if (!mediaKitList) return {}

      const result = {}

      mediaKitList
        .map(getAccountListFromMediaKit)
        .flat()
        .filter(({ account: { platform } = {} }) => [TIKTOK_NATIVE, INSTAGRAM_NATIVE, YOUTUBE_NATIVE].includes(platform))
        .forEach(({ account: { platform, followerCount: backupCount } = {}, userResultStats: { followerCount } = {} }) => {
          result[platform] = (result[platform] || 0) + (followerCount || backupCount || 0)
        })

      return result
    },
    hideRates() { return (this.creatorList || {}).hideRates },
  },
  watch: {
    creatorListId: {
      immediate: true,
      async handler(creatorListId) {
        if (creatorListId) {
          const { creatorList = {} } = this
          const { agencyId, agentUserIdList = [], includeFullRoster, selectedMediaKitIdList = [], limitToAgentList } = creatorList

          if (!includeFullRoster && !selectedMediaKitIdList.length) { // This is because the "in" query breaks if selectedMediaKitIdList is empty
            this.mediaKitList = []
          } else {
            let mediaKitDocList = []
            if (includeFullRoster) {
              const limitToAgentQueryAdditions = limitToAgentList
                ? q => q.where('agencyCreator.agentUserIdList', 'array-contains-any', agentUserIdList)
                : q => q

              const mediaKitsCol = await getCol(AGENCY_MEDIA_KITS_COL_PATH, q => limitToAgentQueryAdditions(q).where('agencyId', '==', agencyId).where('isPrimaryMediaKit', '==', true).where('isActive', '==', true).orderBy('createDate', 'asc'))
              mediaKitDocList = mediaKitsCol.docs || []
            } else {
              const selectedMediaKitIdQueue = [...selectedMediaKitIdList]
              while (selectedMediaKitIdQueue.length > 0) {
                const batch = selectedMediaKitIdQueue.splice(0, 10)
                const mediaKitsCol = await getCol(AGENCY_MEDIA_KITS_COL_PATH, q => q.where((docIdKey()), 'in', batch).where('agencyId', '==', agencyId).where('isActive', '==', true).orderBy('createDate', 'asc'))
                mediaKitDocList.push(...(mediaKitsCol.docs || []))
              }
            }

            const fullMediaKitList = mediaKitDocList
              .map(mediaKit => ({ id: mediaKit.id, ...(mediaKit.data() || {}) }))

            const uniqueIdList = fullMediaKitList
              .map(({ creatorId }) => creatorId)
              .filter((id, i, a) => id && a.indexOf(id) === i)

            const filteredAndSortedList = uniqueIdList
              .map((uniqueId) => {
                const mediaKitList = fullMediaKitList
                  .filter(({ creatorId }) => creatorId === uniqueId)

                const selectedMediaKit = mediaKitList.find(({ id }) => selectedMediaKitIdList.includes(id)) || mediaKitList[0] // default to the oldest media kit
                return selectedMediaKit
              })
              .sort((a, b) => (a.fullName || SORT_END_CHAR).localeCompare(b.fullName || SORT_END_CHAR) || a.id.localeCompare(b.id))

            this.mediaKitList = filteredAndSortedList
          }
        }
      },
    },
  },
  methods: {
    openMediaKit(mediaKit) { this.mediaKitPreview = mediaKit },
    closeMediaKit() { this.mediaKitPreview = undefined },
  },
}
